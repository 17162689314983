import React, { useEffect, useId, useState } from "react";

import ChevronIcon from "components/Icons/ChevronIcon";

type Props = {
  children?: React.ReactNode;
  title: string;
  numberOfItemsSelected: number;
  /**
   * Whether the section should be expanded when initially rendered. Changing
   * the value of this prop after the component is rendered will not update
   * the open state; this value is only used as the initial value when
   * rendering the filter section.
   */
  openDefault?: boolean;
  /**
   * By contrast, this prop actually will trigger a mounted section to open itself.
   */
  open?: boolean;
  onFilterSectionClick?: (title: string) => void;
};

export const FilterSection: React.FC<Props> = ({
  title,
  numberOfItemsSelected,
  children,
  openDefault = false,
  open: externalOpenTrigger,
  onFilterSectionClick,
}) => {
  const [open, setOpen] = useState(openDefault);
  const headerId = useId();
  const panelId = useId();

  useEffect(() => {
    if (typeof externalOpenTrigger === "undefined") {
      return;
    }

    setOpen(externalOpenTrigger);
  }, [externalOpenTrigger]);

  return (
    <div
      className="border-t border-b border-light-grey"
      style={{ marginTop: "-1px" }}
      data-testid="filter-section"
    >
      <button
        aria-controls={panelId}
        aria-expanded={open}
        id={headerId}
        onClick={() => {
          setOpen(!open);
          if (!open && onFilterSectionClick) {
            onFilterSectionClick(title);
          }
        }}
        className="w-full"
      >
        <span className="flex justify-between text-left items-center p-4 md:p-3 md:pl-none">
          <span className="font-bold">{title}</span>
          <div className="flex items-center">
            {!!numberOfItemsSelected && (
              <span
                className="flex justify-center items-center bg-light-grey rounded-full text-xs font-bold mr-xs"
                style={{ height: 26, width: 26 }}
                data-testid="number-of-selected-filters"
              >
                <span>{numberOfItemsSelected}</span>
              </span>
            )}
            <span>
              <ChevronIcon
                height="24"
                width="24"
                direction={open ? "up" : "down"}
              />
            </span>
          </div>
        </span>
      </button>
      <div
        aria-labelledby={headerId}
        className={
          "overflow-hidden pb-3 px-md md:px-none" + (open ? "" : " hidden")
        }
        data-testid="filter-section-content"
        id={panelId}
        role="region"
      >
        {children}
      </div>
    </div>
  );
};
